.ol-popup {
    position: absolute;
    background-color: white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.2);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    max-height: 330px;
    min-width: 250px;
    z-index: 1;
  }
  .ol-popup:after, .ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .ol-popup:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }
  .ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }
  .ol-popup-closer {
    text-decoration: none;
    position: absolute;
    size: 1em;
    color: red;
    top: 0;
    right: 2px;
    z-index: 3;
  }

  .ol-popup-content {
    max-height: 300px; 
    overflow: auto;
    z-index: 2;
  }

  .ol-popup-content span.btn-link {
      cursor: pointer;
      margin-right: 0.5em;
  }
@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css');
.ol-zoom-extent {
    top: 0.5em;
    left: 3em;
}

.ol-zoom-extent-control {
    top: 4.5em;
    left: 0.5em;
}

.ol-zoomslider {
    top: 7em;
}

.ol-center-entity-population {
    top: 0.5em;
    left: 5.5em;
}

.ol-popup-control {
    top: 3em;
    left: 5.5em;
}

.ol-measure-area-control {
    top: 3em;
    left: 3em;
}

.ol-measure-length-control {
    top: 5.5em;
    left: 3em;
}

.ol-add-geometric-element-control {
    top: 5.5em;
    left: 5.5em;
}

#addGeometricElementSelector {
    top: 2.5em !important;
}

.ol-control-active {
    background-color: turquoise !important;
}

.ol-tooltip {
    position: relative;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    color: white;
    padding: 4px 8px;
    opacity: 0.7;
    white-space: nowrap;
}

.ol-tooltip-measure {
    opacity: 1;
    background-color: dodgerblue;
    color: black;
    border: 1px solid white;
}

.ol-tooltip-measure:before {
    border-top: 6px solid dodgerblue;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    content: "";
    position: absolute;
    bottom: -6px;
    margin-left: -7px;
    left: 50%;
}

.ol-show-legend-control {
    top: 8em;
    left: 3em;
}

.ol-scale-bar {
    right: 1em;
    left: auto;
}

.ol-export-pdf-control {
    top: calc( 7.5em + 200px);
    left: 0.5em;
}

#progressiveCenterDiv, #searchCenterDiv {
    max-width: 0;
    transition: max-width .8s ease-in;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 30px;
    height: 30px;
}

#progressiveCenterDiv.show, #searchCenterDiv.show {
    max-width: 85vw;
}

#searchCenterDiv input, #progressiveCenterDiv select, #searchCenterDiv button, #progressiveCenterDiv button {
    display: inline-block;
    margin-left: 0.5em;
}

.autocomplete {
    max-height: 50vh!important;
}

.autocomplete .group {
    font-weight: bolder!important;
}

#progressiveCenterDiv select {
    max-height: auto!important;
}

#centerSearchInput {
    height: auto!important;
    line-height: auto!important;
    margin: 0px;
    padding: 0px;
    min-width: 13vw;
}

.inputError {
    background-color: rgba(255, 17, 0, 0.589);
}

.legend {
    z-index: 1;
    position: fixed;
    min-width: 20vw;
    max-width: 25vw;
    width: auto;
    height: fit-content;
    background-color: #dfdfdf;
    left: 3em;
    top: 14.5em;
    border: 0.5em solid #dfdfdf;
}

#legendAccordion {
    overflow-y: auto;
    min-height: 5vh;
    max-height: 60vh;
}

#legendAccordion>div.card>div.card-header {
    cursor: pointer;
    font-weight: bold;
}

#legendAccordion>div.card>div.card-header::before {
    font-family: 'FontAwesome';
    content: "\f078";
    /* fa-chevron-down */
    margin-right: 5px;
}

#legendAccordion>div.card>div.card-header[aria-expanded="true"]::before {
    content: "\f077";
    /* fa-chevron-up */
}

#closeLegend {
    text-decoration: none;
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 3;
}

.ol-mouse-position {
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: #eeeeee;
}